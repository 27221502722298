import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBusinessProfile } from "src/app/views/customers/models/ICustomer.model";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class CustomerService {
    constructor(private http: HttpClient) {

    }

    userById() {
        return this.http.get<any>(
            `${environment.services.backend}/customers/profile`);
    }

    supplierInformation(code: string) {
        const res = this.http.get(
            `${environment.services.backend}/customers/supplier/${code}/info`,
            {
                headers: {
                    "Accept": "application/json"
                }
            }
        );

        return res;
    }

    saveBasicInformation(data: any) {
        const res = this.http.put(
            `${environment.services.backend}/customers/profile/basic`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        );
        return res;
    }

    saveBusinessInformation(data: IBusinessProfile) {
        return this.http.put(
            `${environment.services.backend}/customers/profile/business`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        );
    }

    saveEmailContact(data:any) {
        const res = this.http.put(
            `${environment.services.backend}/customers/profile/contact-email`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        );
        return res;
    }

    providerHasLicense(code:string) {
        return this.http.get(
            `${environment.services.backend}/customers/supplier/${code}/has-license`
        );
    }

    retrieveUsersList() {
        return this.http.get(
            `${environment.services.backend}/customers/users`
        );
    }

    saveUserInformation(uid:string, data:any) {
        return this.http.patch(
            `${environment.services.backend}/customers/user/${uid}`,
            data
        );
    }

    saveUserInformationMassive(data:any) {
        return this.http.patch(
            `${environment.services.backend}/customers/users`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
    }
}