<div class="main-header">
        <div class="logo">
            <img src="./assets/images/logo-small.png" alt="stopandbook+">
        </div>

        <div class="menu-toggle" (click)="toggelSidebar()">
            <div></div>
            <div></div>
            <div></div>
        </div>

        <div class="d-flex align-items-center">
            <!-- Mega menu -->
            <div ngbDropdown class="mega-menu d-none d-md-block">
                <button ngbDropdownToggle href="#" class="btn text-muted dropdown-toggle me-3">Menu</button>
                <div ngbDropdownMenu perfectScrollbar>
                    <div class="row m-0">
                        <div class="col-md-4 p-4 bg-img">
                            <h2 class="title">Bienvenido <br> Administrador</h2>
                            <p>En este portal podr&aacute;s configurar el comportamiento de tu negocio
                            </p>
                            <p class="mb-4">Configura proveedores, crea reglas de formas de pago.</p>
                            <button class="btn btn-lg btn-rounded btn-outline-warning">Mi cuenta</button>
                        </div>
                        <div class="col-md-4 p-4">
                            <p class="text-primary text--cap border-bottom-primary d-inline-block">Mi Negocio</p>
                            <div class="menu-icon-grid w-auto p-0">
                                <a routerLink="/"><i class="i-Shop-4"></i> Home</a>
                                <a routerLink="/suppliers"><i class="i-Clouds-Weather"></i> Proveedores</a>
                                <a routerLink="/payment-suppliers"><i class="i-Financial"></i> Procesadores de Pago</a>
                                <a routerLink="/customers/my-company"><i class="i-Checked-User"></i> Mi Empresa</a>
                                <a routerLink="/"><i class="i-Support"></i> Support</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Search bar -->
            <div class="search-bar">
                <input type="text" placeholder="Search" (focus)="searchService.searchOpen = true">
                <i class="search-icon text-muted i-Magnifi-Glass1"></i>
            </div>
        </div>

        <div style="margin: auto"></div>

        <div class="header-part-right">
            <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i>
            <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
            <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
                <i ngbDropdownToggle class="i-Gear text-muted header-icon" role="button"></i>
                <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                    <div class="menu-icon-grid">
                        <a routerLink="/"><i class="i-Shop-4"></i> Home</a>
                        <a routerLink="/suppliers"><i class="i-Clouds-Weather"></i> Proveedores</a>
                        <a routerLink="/payment-suppliers"><i class="i-Financial"></i> Formas de Pago</a>
                        <!-- <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a> -->
                        <a routerLink="/customers/my-company"><i class="i-Checked-User"></i> Mi Empresa</a>
                        <a routerLink="/"><i class="i-Support"></i> Soporte</a>
                    </div>
                </div>
            </div>

            <div ngbDropdown [placement]="'bottom-right'">
                <div class="badge-top-container">
                    <span class="badge badge-primary">{{notifications?.length}}</span>
                    <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
                </div>
                <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                    <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">
                        <div class="notification-icon">
                            <i class="{{item.icon}} text-{{item.status}} me-1"></i>
                        </div>
                        <div class="notification-details flex-grow-1">
                            <p class="m-0 d-flex align-items-center">
                                <span>{{item.title}}</span>
                                <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ms-1 me-1">{{item.badge}}</span>
                                <span class="flex-grow-1"></span>
                                <span class="text-small text-muted ms-auto">{{item.time | relativeTime}}</span>
                            </p>
                            <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
                <img [src]="urlLogo$ | async" id="userDropdown" ngbDropdownToggle alt="">

                <div ngbDropdownMenu aria-labelledby="userDropdown">
                    <div class="dropdown-header">
                        <i class="i-Lock-User me-1"></i> {{ commercialName$ | async }}
                    </div>
                    <button class="dropdown-item">Mi Cuenta</button>
                    <button class="dropdown-item">Mis Pagos</button>
                    <button class="dropdown-item" (click)="signout()">Sign out</button>
                </div>
            </div>

        </div>
    </div>